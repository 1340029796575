// 时间戳转成年月日
export function getLocalTime(datetimes) {
  if (!datetimes) {
    return '--'
  }
  var date = new Date(datetimes)

  return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(date)
}

// 时间戳转成年月日
export function getLocalTime2(datetimes) {
  if (!datetimes) {
    return '--'
  }
  var date = new Date(datetimes)

  return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(date)
}

export function getLocalDateTime(datetimes) {
  if (!datetimes) {
    return '--'
  }
  var date = new Date(datetimes)
  var Y = date.getFullYear() + '-'
  var M1 = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = add0(date.getDate())
  return Y + M1 + D
}

export function getLocalMonth(datetimes) {
  if (!datetimes) {
    return '--'
  }
  var date = new Date(datetimes)
  var Y = date.getFullYear() + '/'
  var M1 = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
  var D = add0(date.getDate())
  return Y + M1
}
// 计算指定时间是星期几
export function getweekday(date) {
  var weekArray = new Array(this.$t('weekly.Sunday'), this.$t('weekly.Monday'), this.$t('weekly.thuesday'), this.$t('weekly.Wednesday'), this.$t('weekly.Thursday'), this.$t('weekly.Friday'), this.$t('weekly.Saturday'))
  var week = weekArray[new Date(date).getDay()]
  return week
}

export function getLocalMinutesTime(datetimes) {
  if (!datetimes) {
    return '--'
  }

  var date = new Date(datetimes)

  var Y = date.getFullYear() + '/'
  var M1 = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/'
  var D = (date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate())
  var H = ' ' + (date.getHours() < 10 ? ('0' + date.getHours()) : (date.getHours() + '')) + ':'
  var M = (date.getMinutes() < 10 ? ('0' + date.getMinutes()) : (date.getMinutes() + ''))
  return Y + M1 + D + H + M
}
export function getLocalSecondTime(datetimes) {
  if (!datetimes) {
    return '--'
  }
  var date = new Date(datetimes)
  var Y = date.getFullYear() + '/'
  var M1 = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/'
  var D = (date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate())
  var H = ' ' + (date.getHours() < 10 ? ('0' + date.getHours()) : (date.getHours() + '')) + ':'
  var M = (date.getMinutes() < 10 ? ('0' + date.getMinutes()) : (date.getMinutes() + '')) + ':'
  var S = (date.getSeconds() < 10 ? ('0' + date.getSeconds()) : (date.getSeconds() + '')) + ' '
  return Y + M1 + D + H + M + S
}

export function formatDate(timestamp, i18n) {
  // console.log(i18n.t("notFoundPage.returnHome"));

  timestamp = timestamp.getTime()
  const minute = 1000 * 60
  const hour = minute * 60
  const day = hour * 24
  const month = day * 30
  const now = new Date().getTime()
  const diffValue = now - timestamp
  // 如果本地时间反而小于变量时间
  if (diffValue < 0) {
    return i18n.t('jsChinses.NotLongAgo')
  }
  // 计算差异时间的量级
  const monthC = diffValue / month
  const weekC = diffValue / (7 * day)
  const dayC = diffValue / day
  const hourC = diffValue / hour
  const minC = diffValue / minute
  // 数值补0方法
  const zero = function(value) {
    if (value < 10) {
      return '0' + value
    }
    return value
  }
  // 使用
  if (monthC > 4) {
    // 超过1年，直接显示年月日
    return (function() {
      const date = new Date(timestamp)
      return date.getFullYear() + i18n.t('jsChinses.year') + zero(date.getMonth() + 1) + i18n.t('jsChinses.month') + zero(date.getDate()) + i18n.t('jsChinses.day')
    })()
  } else if (monthC >= 1) {
    return parseInt(monthC) + i18n.t('jsChinses.monthbefore')
  } else if (weekC >= 1) {
    return parseInt(weekC) + i18n.t('jsChinses.weeklybefore')
  } else if (dayC >= 1) {
    return parseInt(dayC) + i18n.t('jsChinses.daybefore')
  } else if (hourC >= 1) {
    return parseInt(hourC) + i18n.t('jsChinses.hoursbefore')
  } else if (minC >= 1) {
    return parseInt(minC) + i18n.t('jsChinses.minutesbefore')
  }
  return i18n.t('jsChinses.just')
}
export function lastMonth() {
  var nowdate = new Date()
  nowdate.setMonth(nowdate.getMonth() - 1)
  var y = nowdate.getFullYear()
  var m = nowdate.getMonth() + 1
  var d = nowdate.getDate()
  m = m >= 10 ? m : ('0' + m)
  d = d >= 10 ? d : ('0' + d)
  var formatwdate = y + '/' + m + '/' + d + ' ' + '00:00:00'

  return formatwdate
}

export function nowMonth() {
  var nowdate = new Date()
  var y = nowdate.getFullYear()
  var m = nowdate.getMonth() + 1
  var d = nowdate.getDate()
  m = m >= 10 ? m : ('0' + m)
  d = d >= 10 ? d : ('0' + d)
  var formatnowdate = y + '/' + m + '/' + d + ' ' + '23:59:59'
  return formatnowdate
}

export function dateChange(date) {
  var d = new Date(date)
  var n = d.getFullYear()
  var y = d.getMonth() + 1
  var day = d.getDate()
  y = y >= 10 ? y : ('0' + y)
  day = day >= 10 ? day : ('0' + day)
  var datetime = n + '/' + y + '/' + day

  return datetime
}

export function dateChangeEnd(date) {
  var d = new Date(date)
  var n = d.getFullYear()
  var y = d.getMonth() + 1
  var day = d.getDate()
  y = y >= 10 ? y : ('0' + y)
  day = day >= 10 ? day : ('0' + day)
  var datetime = n + '/' + y + '/' + day + ' ' + '23:59:59'

  return datetime
}

export function getStandardDateBeforeWeek() {
  var _date = new Date() // 获取今天日期
  _date.setDate(_date.getDate() - 7) // 日期回到七天前
  var year = _date.getFullYear()
  var month = _date.getMonth() + 1
  var day = _date.getDate()
  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  var dateTemp = year + '/' + month + '/' + day
  _date.setDate(_date.getDate() + 7) // 日期重置
  return dateTemp
}

export function dateNow() {
  var date = new Date()
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate()
  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  var nowDate = year + '/' + month + '/' + day
  console.log(nowDate)

  return nowDate
}

function add0(m) { return m < 10 ? '0' + m : m }

// 时间戳转化成时间格式
export function timeFormat(timestamp) {
  // timestamp是整数，否则要parseInt转换,不会出现少个0的情况
  var time = new Date(timestamp)
  var year = time.getFullYear()
  var month = time.getMonth() + 1
  var date = time.getDate()
  var hours = time.getHours()
  var minutes = time.getMinutes()
  var seconds = time.getSeconds()
  return year + '/' + add0(month) + '/' + add0(date) + ' ' + add0(hours) + ':' + add0(minutes) + ':' + add0(seconds)
}
export function yyr(datetimes) {
  if (!datetimes) {
    return '0000-00-00'
  }
  var date = new Date(datetimes)
  var Y = date.getFullYear() + '/'
  var M1 = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/'
  var D = date.getDate()
  var H = ' ' + (date.getHours() < 10 ? ('0' + date.getHours()) : (date.getHours() + '')) + ':'
  var M = (date.getMinutes() < 10 ? ('0' + date.getMinutes()) : (date.getMinutes() + '')) + ':'
  var S = (date.getSeconds() < 10 ? ('0' + date.getSeconds()) : (date.getSeconds() + '')) + ' '
  return Y + M1 + D
}
export function steps_date(num) {
  var date1 = new Date()
  var time1 = date1.getFullYear() + '-' + ((date1.getMonth() + 1) >= 10 ? (date1.getMonth() + 1) : ('0' + (date1.getMonth() + 1))) + '-' + (date1.getDate() >= 10 ? date1.getDate() : ('0' + date1.getDate())) // time1表示当前时间
  var date2 = new Date(date1)
  date2.setDate(date1.getDate() + num)
  var time2 = date2.getFullYear() + '-' + ((date2.getMonth() + 1) >= 10 ? (date2.getMonth() + 1) : ('0' + (date2.getMonth() + 1))) + '-' + (date2.getDate() >= 10 ? date2.getDate() : ('0' + date2.getDate())) // time1表示当前时间
  return [time1, time2]
}
