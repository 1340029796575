<template>
  <div class="custom-review">
    <div class="average-star flex-row-center-start">
      <div class="average-star-left">
        <div class="flex-row-center-start">
          <div class="score-icon">
            <el-rate v-model="commentStatData.avgScore" disabled />

          </div>
          <p class="star-text">Average Star Rating: <i>{{ commentStatData.avgScore.toFixed(1) }}</i> out of 5</p>

        </div>
        <p class="note flex-row-center-start">note: <i>All information displayed is based on feedback received
          for this product over the past 6 months. To learn more about our Feedback Rating System</i></p>
      </div>
      <div class="average-star-right">
        <ul class="stars-div">
          <li v-for="(item, index) in list" :key="index">
            <span>{{ item.num }} stars </span>
            <span class="height-10">
              <i class="top" :style="`width:${setWidth(commentStatData.total, item.scoreCount)}%`" />
            </span>
            <span class="count">{{ item.scoreCount }}</span>
          </li>

        </ul>
        <div v-show="false">
          <img src="~@/assets/details/pingfen.png" alt="">
        </div>
      </div>
    </div>
    <p class="rating-title">Rating({{ commentStatData.total }})</p>
    <div class="rating">
      <div v-for="(item, index) in appraiseList" :key="`${index}_`" class="rating-item flex">
        <div class="rating-item-left">
          <p>{{ item.goodsName }}</p>
          <div v-for="(specItem) in item.goodsSpecAndValues" :key="specItem.specId" class="gg">
            <i>{{ specItem.specName }}:</i><b>{{ specItem.valueName }}</b>
          </div>
        </div>
        <div class="rating-item-right">
          <div class="user-info">
            <img  class="user-face-url" :src="item.userFaceUrl">
            <p>{{ item.userName }}</p>
          </div>
          <p class="user flex">
            <el-rate v-model="item.score" disabled text-color="#ff9900" />
            <span v-if="item.userLocation">Reviewed in {{ getThe(item.userLocation) }}{{ item.userLocation }}</span>
            <span> on {{ getLocalDateTime(item.cdt) }}</span>
          </p>
          <p class="customer-review-text">{{ item.comments }}</p>
          <div class="swiper">
            <div class="swiper-wrapper">
              <div v-for="(citem, i) in item.imgUrls" :key="`${i}/${i}`" class="swiper-slide">
                <el-image
                  style="width: 60px; height: 60px"
                  :src="citem"
                  :preview-src-list="item.imgUrls"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLocalTime, getLocalTime2,getLocalDateTime } from '@/utils/date'

import Swiper from 'swiper' // 导入您需要的模块
import 'swiper/dist/css/swiper.css'

export default {
  props: {
    appraiseList: {
      type:Array,
      
    },
    commentStatData: {}
  },
  data() {
    return {
      getLocalTime,
      getLocalTime2,
      getLocalDateTime,
      value: 5,
      list: []

    }
  },
  mounted() {
    console.log(this.appraiseList)
    this.list = [
      { scoreCount: this.commentStatData.score5Count, num: 5 },
      { scoreCount: this.commentStatData.score4Count, num: 4 },
      { scoreCount: this.commentStatData.score3Count, num: 3 },
      { scoreCount: this.commentStatData.score2Count, num: 2 },
      { scoreCount: this.commentStatData.score1Count, num: 1 }
    ]
  },
  methods: {
    getThe(location) {
      if (location.toUpperCase().startsWith('A') ||
        location.toUpperCase().startsWith('E') ||
        location.toUpperCase().startsWith('I') ||
        location.toUpperCase().startsWith('O') ||
        location.toUpperCase().startsWith('U')) {
        return 'the '
      } else {
        return ''
      }
    },
    SeeImg(imgs) {
      this.$ImagePreview({
        images: imgs,
        closeable: true
      })
    },
    setWidth(count, startCount) {
      return (startCount / count) * 100
    }
  }
}
</script>

<style lang="less" scoped>
.custom-review {
  padding-bottom: 20px;

  .average-star {
    padding-top: 30px;

    .average-star-left {
      width: 50%;

      .score-icon {
        display: flex;
        align-items: center;

        img {
          margin-right: .1rem;
          height: .48rem;
          width: .48rem;
        }

        /deep/ .el-rate {
          height: 0.7rem;

        }

        /deep/ .el-rate__icon {
          font-size: 0.7rem;
        }
      }

      .star-text {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-left: 16px;

        i {
          color: #FF6F00
        }
      }

      .note {
        font-size: 14px;
        font-weight: 400;
        padding-top: 13px;
        align-items: flex-start;
      }
    }

    .average-star-right {
      .stars-div {
        height: 150px;
        background: url('~@/assets/details/bg.png') no-repeat;
        background-size: cover;
        padding: 10px;

        span {
          font-size: 16px;
          font-weight: bold;

        }

        li {
          padding: 3px 10px 3px 15px;
        }

        .height-10 {
          display: inline-block;
          height: 10px;
          width: 100px;
          background: #D9D9D9;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          position: relative;

          .top {
            height: 100%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            position: absolute;
            top: 0;
            left: 0;
            background: #FFC120;
          }
        }

        .active-height-10 {
          background: #FFC120;

        }

        .count {
          color: #FFC120;
          padding-left: 5px;
        }
      }
    }
  }

  .rating-title {
    font-size: 16px;
    font-weight: bold;
    color: #010101;
    padding-bottom: 6px;
  }

  .rating {
    width: 100%;
    background: #F9F9F9;
    border-top: 1px solid #eaeaea;

    .rating-item {
      padding-top: 31px;
      padding-bottom: 28px;
      border-bottom: 1px dashed #eaeaea;

      &:nth-child(odd) {
        background: #ffffff;
      }
    }

    .rating-item-left {
      width: 45%;
      border-right: 1px solid #eaeaea;
      padding-left: 10px;
      padding-right: 20px;

      p {
        font-size: 16px;
        color: #000000;
        font-weight: bold;
        padding-bottom: 6px;
      }

      .gg {
        font-size: 15px;

        i {
          color: #00CCCC;
          font-weight: 500;
          font-size: 16px;
        }

        b {
          color: #666666;
        }
      }
    }

    .rating-item-right {
      width: 55%;
      padding-left: 30px;
      padding-right: 13px;

      .user-info{
        display: flex;
        align-items: center;
        .user-face-url{
          width: 35px;
          height: 35px;
          margin-right: 5px;
        }
      }

      p {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }

      .user {
        font-size: 14px;
        color: #999999;
        font-weight: 400;
        padding-top: 5px;
      }

      .customer-review-text {
        font-size: 14px;
        color: #666666;
        font-weight: bold;
        padding-top: 5px;
        padding-bottom: 15px;
      }

      .swiper-slide {
        width: 60px;
        height: 60px;
        margin-left: 4px;
        margin-right: 4px;
        box-sizing: border-box;

        .el-image {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
